export default function Close({
  className = "w-4 fill-current stroke-current"
}) {
  return (
    <svg
      viewBox="0 0 20 20"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M18.4 0L20 1.6l-8.4 8.401L20 18.4 18.4 20 10 11.6 1.6 20 0 18.4 8.4 10 0 1.6 1.6 0l8.399 8.4L18.4 0z" />
    </svg>
  )
}
