import Link from "next/link"
import styles from "./siteLogo.module.css"
import Falcon from "@/components/common/logos/falcon"
import Text from "@/components/common/logos/text"
import { useCopy } from "@/lib/contexts/appContext"
import classNames from "classnames"

export function SiteLogo({ className }) {
  const copy = useCopy()
  const { site } = copy.general ?? {}

  return (
    <Link
      href="/"
      prefetch={false}
      className={classNames(styles.logo, className, { "no-site": !site })}>
      <Falcon className="falcon" />
      <div className="text-wrapper">
        <Text className="text" />
        {site && <span>{site}</span>}
      </div>
    </Link>
  )
}
