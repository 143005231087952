export const pushDataLayer = (dataLayer) => {
  if (!dataLayer) {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayer)
}

export const trackSearch = (phrase, context) => {
  if (typeof phrase === "string" && phrase.length === 0) {
    return
  }

  pushDataLayer({
    event: "search",
    search_term: phrase,
    context: context
  })
}
