import { useRef, useState } from "react"
import useOnClickOutside from "@/lib/hooks/useOnClickOutside"
import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/menu"
import Close from "@/components/common/icons/close"
import Burger from "@/components/common/icons/menu"
import classNames from "classnames/bind"
import styles from "./primary.module.css"
import MenuTop from "./top"
import Support from "./support"
import { submenu } from "./menu.module.css"

export default function Primary({
  isActive,
  setActive,
  isMobile,
  isNarrow,
  hasMounted
}) {
  const offCanvasRef = useRef(null)
  const cx = classNames.bind(styles)
  const [supportOpen, setSupportOpen] = useState(false)

  const handleSupport = (_, isOpen) => {
    setSupportOpen(isOpen)
  }

  useOnClickOutside(offCanvasRef, () => setActive("offCanvas", false), isActive)

  const items = useMenuItems("primary")

  if (!items || items.length === 0) {
    return null
  }

  if (hasMounted && !isNarrow) {
    return null
  }

  return (
    <div className={styles.primary} ref={offCanvasRef} role="primary">
      <button
        type="button"
        className="toggle"
        onClick={() => setActive("offCanvas", !isActive)}>
        {isActive ? <Close className="close" /> : <Burger className="burger" />}
      </button>
      {items.length > 0 && (
        <div className={cx({ submenu: true, on: isActive }, submenu)}>
          {isActive && (
            <nav role="scroll-wrapper">
              <Menu menuClasses="primary" items={items} location="primary" />
              <Support
                setActive={handleSupport}
                isActive={supportOpen}
                inPrimary={true}
                {...{ hasMounted, isMobile, isNarrow }}
              />
              <MenuTop
                hasMounted={hasMounted}
                isNarrow={false}
                inPrimary={true}
              />
            </nav>
          )}
        </div>
      )}
    </div>
  )
}
