import { useEffect, useRef, useState } from "react"
import Magnifier from "@/components/common/icons/magnifier"
import Close from "@/components/common/icons/close"
import useOnClickOutside from "@/lib/hooks/useOnClickOutside"
import { useRouter } from "next/router"
import { useAppContext } from "@/lib/contexts/appContext"
import { trackSearch } from "@/lib/helpers/tagManager"
import LogoSpinner from "@/components/common/loading/logo-spinner"
import styles from "./search.module.css"
import classNames from "classnames"

export function Search({ isActive, setActive }) {
  const router = useRouter()
  const searchRef = useRef(null)
  const searchInputRef = useRef(null)
  const { searchFormRef, object } = useAppContext()
  const [inputValue, setInputValue] = useState("")
  const [isSearching, setSearching] = useState(false)
  const placeholder = "Sök efter..."

  useOnClickOutside(searchRef, () => setActive("search", false), isActive)

  const toggleForm = () => {
    if (router.asPath.includes("/sok/")) {
      if (searchFormRef.current) {
        searchFormRef.current.focus()
        searchFormRef.current.select()
      }
      return false
    }

    setSearching(false)
    if (searchInputRef.current) {
      searchInputRef.current.disabled = false
      searchInputRef.current.placeholder = placeholder
    }
    setActive("search", !isActive)
  }

  useEffect(() => {
    if (isActive) {
      setTimeout(() => searchInputRef.current.focus(), 100)
    }
  }, [isActive])

  if (object.theme?.hideSearch) {
    return null
  }

  const Icon = () => {
    return isActive ? (
      <Close className="close" />
    ) : (
      <Magnifier className="magnifier" />
    )
  }

  return (
    <div className={styles.search} ref={searchRef} role="search">
      <button
        type="button"
        className={classNames({ on: isActive })}
        onClick={toggleForm}>
        <Icon />
      </button>
      {isActive && (
        <form
          onSubmit={(event) => {
            event.preventDefault()
            setSearching(true)
            setInputValue("")
            searchInputRef.current.disabled = true
            searchInputRef.current.placeholder = ""
            trackSearch(searchInputRef.current.value, "site")
            router.push({
              pathname: "/sok/",
              query: { s: searchInputRef.current.value }
            })
          }}
          className={styles.searchForm}>
          <button>
            <Magnifier />
          </button>
          <input
            onChange={(e) => setInputValue(e.target.value)}
            ref={searchInputRef}
            value={inputValue}
            type="search"
            placeholder={placeholder}
            required
          />
          {isSearching && <LogoSpinner className="spinner" />}
        </form>
      )}
    </div>
  )
}
