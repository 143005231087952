import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/simple"

export default function FooterMenu({ heading }) {
  const location = "footer"
  const items = useMenuItems(location)

  if (!items) return null

  return (
    <div className="column">
      <h4>{heading}</h4>
      <Menu items={items} location={location} />
    </div>
  )
}
