import { useState } from "react"
import Link from "./link"
import Menu from "./menu"
import { Caret } from "@/common/icons/caret"
import OpenInNew from "@/common/icons/open-in-new"
import classNames from "classnames"

export default function Item({
  item,
  className,
  location,
  icon = false,
  hover = false
}) {
  const hasSubMenu = item?.items && item.items.length > 0
  const isExternal = item.url.substr(0, 4) === "http"
  const [open, setOpen] = useState(false)

  return (
    <li
      className={className}
      onMouseEnter={() => hover && hasSubMenu && setOpen(true)}
      onMouseLeave={() => hover && hasSubMenu && setOpen(false)}>
      <Link item={item} location={location}>
        {hasSubMenu && (
          <div
            className="toggle"
            onClick={(e) => {
              e.preventDefault()
              !hover && setOpen(!open)
            }}>
            <Caret className={classNames({ caret: true, open: open })} />
          </div>
        )}
        {icon && !isExternal && (
          <Caret className={classNames({ caret: true, right: true })} />
        )}
        {icon && isExternal && (
          <OpenInNew
            className={classNames({ "open-in-new": true, right: true })}
          />
        )}
      </Link>
      {open && (
        <Menu
          menuClasses="sub-menu"
          items={item.items}
          location={"sub-" + location}
        />
      )}
    </li>
  )
}
