import classNames from "classnames"
// FIXME this Caret is not used in the design should be replaced by this
export function Caret({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 6"
      className={classNames(["fill-current", className])}>
      <path d="M9 .5H1a.5.5 0 0 0-.354.853l3.984 4a.5.5 0 0 0 .707.001l4.016-4A.5.5 0 0 0 9 .5Z" />
    </svg>
  )
}

export function CaretDown({ className = "" }) {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 11 6"
      className={`fill-current ${className}`}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1h8L4.984 5z" />
    </svg>
  )
}

export function CaretRight({ className }) {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      className={`fill-current ${className}`}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1 10V0l5 5.02z" />
    </svg>
  )
}

export function CaretRounded({ className, fillColor = "#101010" }) {
  return (
    <svg
      viewBox="0 0 10 6"
      version="1.1"
      width="10"
      height="6"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1131.000000, -496.000000)" fillRule="nonzero">
          <g transform="translate(1000.000000, 469.000000)" fill={fillColor}>
            <path
              d="M133.5,26 L133.5,34 C133.5,34.444983 134.037601,34.6682784 134.352864,34.3542413 L138.352864,30.3697928 C138.548507,30.1749103 138.549123,29.8583264 138.354239,29.6626847 L134.354239,25.6471332 C134.039538,25.3312088 133.5,25.5540797 133.5,26 Z"
              transform="translate(136.000000, 30.000000) rotate(90.000000) translate(-136.000000, -30.000000) "></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export function CaretLarge({ className }) {
  return (
    <svg viewBox="0 0 15 10" className={className}>
      <defs />
      <path
        fill="#101010"
        fillRule="nonzero"
        d="M13.914 8.914a1 1 0 00.083-1.32l-.083-.094L7.207.793.5 7.5a1 1 0 001.32 1.497l.094-.083 5.293-5.292L12.5 8.914a1 1 0 001.32.083l.094-.083z"
      />
    </svg>
  )
}
