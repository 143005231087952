import { default as NextLink } from "next/link"

export default function Link({ item, location, className, children }) {
  const isExternal = item.url.substr(0, 4) === "http"
  const target = item.target === "_blank" ? item.target : "_self"

  return !isExternal ? (
    <NextLink
      href={item.url}
      prefetch={false}
      className={className}
      title={item.label}
      data-menu={location}
      data-type="app-route">
      {item.label}
      {children}
    </NextLink>
  ) : (
    <a
      className={className}
      href={item.url}
      target={target}
      title={item.label}
      data-type={isExternal ? "external" : "regular"}
      data-menu={location}>
      {item.label}
      {children}
    </a>
  )
}
