import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import {
  usePathData,
  usePropsObject,
  useAppContext
} from "@/lib/contexts/appContext"
import { maybeValidUser, getUser, deleteUser } from "@/lib/helpers/user"
import classNames from "classnames"
import WordPress from "@/common/icons/user/wordpress"
import Dashboard from "@/common/icons/user/dashboard"
import Edit from "@/common/icons/user/edit"
import styles from "./admin-bar.module.css"

export default function AdminBar() {
  const [user, setUser] = useState(null)
  const [menuActive, setMenuActive] = useState(false)
  const pathData = usePathData()
  const object = usePropsObject()
  const { meta } = useAppContext()

  const router = useRouter()
  const adminURL = process.env.NEXT_PUBLIC_API_URL.replace(
    "/graphql",
    "/" + meta?.context + "/wp-admin/"
  )
  const editURL = object?.id
    ? adminURL + "post.php?post=" + object.id + "&action=edit"
    : null

  const doLogout = () => {
    deleteUser()
    router.reload()
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (maybeValidUser()) {
        setUser(getUser())
      }
    }
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  if (!user) {
    return null
  }

  return (
    <div className={styles.bar}>
      <div className="actions">
        <WordPress className="wp-logo" />
        <a href={adminURL} className="action" target="_blank" rel="noreferrer">
          <Dashboard className="icon" />
          <span>Adminpanel</span>
        </a>
        {editURL && (
          <a href={editURL} className="action" target="_blank" rel="noreferrer">
            <Edit className="icon" />
            <span>Redigera</span>
          </a>
        )}
      </div>
      <div className="user" onClick={() => setMenuActive(!!setMenuActive)}>
        <span>Hej, {user.name}</span>
        <img className="icon" src={user.avatar} alt="avatar" />
        <div className={classNames("menu", { "is-active": menuActive })}>
          <img className="avatar" src={user.avatar} alt="avatar" />
          <a onClick={() => doLogout()}>Logga ut</a>
        </div>
      </div>
    </div>
  )
}
