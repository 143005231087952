import { useRouter } from "next/router"
import { useMemo, useEffect, useState } from "react"
import { useMediaPredicate } from "react-media-hook"
import { useCopy } from "@/lib/contexts/appContext"
import { useFirstBlock } from "@/lib/helpers/template"
import Overlay from "./overlay"
import AdminBar from "./admin-bar"
import MenuTop from "./menus/top"
import Featured from "./menus/featured"
import Support from "./menus/support"
import Primary from "./menus/primary"
import { Search } from "./search"
import classNames from "classnames"
import styles from "./header.module.css"
import { SiteLogo } from "@/common/logos/siteLogo"

export default function Header({ hasSidebar }) {
  const copy = useCopy()
  const { search, support } = copy?.header ?? {}

  const defaultState = useMemo(() => {
    return {
      search: false,
      supportUs: false,
      offCanvas: false,
      overlay: false
    }
  }, [])

  const firstBlock = useFirstBlock()
  const isMobile = useMediaPredicate("(max-width: 640px)")
  const isNarrow = useMediaPredicate("(max-width: 1024px)")
  const [hasMounted, setMounted] = useState(false)
  const [menuColor, setMenuColor] = useState(
    "text-black-100 border-black-100/10 bg-white"
  )

  // We want to force the narrow menu when page has sidebar
  const narrowMenu = isNarrow || (!isNarrow && hasSidebar)

  useEffect(() => {
    setMenuColor(getMenuColor(firstBlock))
  }, [firstBlock])

  // This is used due to isMobile not working before mount
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setMounted(true)
    }
    return () => {
      isMounted = false
    }
  }, [])

  const [state, setState] = useState(defaultState)
  const [scrollY, setScrollY] = useState(0)
  const router = useRouter()

  const handleClick = (location, open) => {
    setState({
      ...defaultState,
      overlay: open,
      [location]: open
    })
  }

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && state.overlay) {
        setState({ ...defaultState })
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [state.overlay, defaultState])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true
    })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = () => setState({ ...defaultState })

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events, defaultState])

  const classes = [styles.header, scrollY > 10 ? styles.opaque : menuColor]

  return (
    <>
      <header
        id="header"
        className={classNames(classes, { "offcanvas-on": state.offCanvas })}>
        <AdminBar />
        <MenuTop hasMounted={hasMounted} isNarrow={narrowMenu} />
        <div className="menu-bar">
          <SiteLogo className="logo" />
          <Featured isNarrow={narrowMenu} hasMounted={hasMounted} />
          {search && <Search isActive={state.search} setActive={handleClick} />}
          {support && (
            <Support
              setActive={handleClick}
              isActive={state.supportUs}
              {...{ hasMounted, isMobile, isNarrow }}
            />
          )}
          <Primary
            hasMounted={hasMounted}
            isNarrow={narrowMenu}
            isMobile={isMobile}
            isActive={state.offCanvas}
            setActive={handleClick}
          />
        </div>
      </header>
      <Overlay active={state.overlay} />
    </>
  )
}

export function getMenuColor(firstBlock) {
  let textColor = "text-black-100"
  let borderColor = "border-black-100/10"
  let bgColor = "bg-white"

  if (hasWhiteHeader(firstBlock)) {
    textColor = "text-white"
    borderColor = "border-white"
  }

  if (hasTransparentBg(firstBlock)) {
    bgColor = "bg-transparent"
  }

  return [textColor, borderColor, bgColor].join(" ")
}

export function hasWhiteHeader(firstBlock) {
  if (!firstBlock) {
    return false
  }

  switch (firstBlock.name) {
    case "theme/hero":
      if (firstBlock.attrs.image.tall || firstBlock.attrs.image.wide) {
        return true
      }
      break

    case "theme/calendar":
      if (firstBlock.attrs.image) {
        return true
      }
      break

    default:
      return false
  }

  return false
}

export function hasTransparentBg(firstBlock) {
  if (
    firstBlock?.name === "theme/persons" ||
    firstBlock?.name === "theme/archive" ||
    firstBlock?.name === "theme/search" ||
    firstBlock?.name === "theme/hero"
  ) {
    return true
  }
  return false
}
