import Item from "./item"
import classNames from "classnames"

export default function Menu({
  menuClasses,
  items,
  location,
  icon = false,
  hover = false
}) {
  if (!items || items?.length === 0) {
    return null
  }

  return (
    <ul
      className={classNames([menuClasses, { "has-icon": icon }])}
      role={location + "-menu"}>
      {items.map((item, index) => (
        <Item
          icon={icon}
          hover={hover}
          key={location + item.url + index}
          location={location}
          item={item}
        />
      ))}
    </ul>
  )
}
