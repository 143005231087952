import { useRef } from "react"
import { useCopy } from "@/lib/contexts/appContext"
import Link from "@/components/common/menu/link"
import Menu from "@/components/common/menu/menu"
import { CaretRounded } from "@/components/common/icons/caret"
import classNames from "classnames/bind"
import styles from "./support.module.css"
import { submenu } from "./menu.module.css"

export default function Support({
  hasMounted,
  isMobile,
  isNarrow,
  setActive,
  isActive,
  inPrimary = false
}) {
  const copy = useCopy()
  const { support } = copy?.header ?? {}

  const supportUsRef = useRef(null)
  const cx = classNames.bind(styles)

  if (!hasMounted) {
    return null
  }

  if (!inPrimary && (isMobile || isNarrow)) {
    return null
  }

  if (!support) {
    return null
  }

  let { url, target, label, items } = support

  if (isMobile) {
    items = [{ url: url, target: target, label: label, items: [] }, ...items]
  }

  const handleClick = () => {
    if (!inPrimary) {
      return
    }

    setActive("supportUs", !isActive)
  }

  return (
    <div
      className={classNames(styles.support, { "in-primary": inPrimary })}
      ref={supportUsRef}
      onMouseEnter={() =>
        !isMobile && !inPrimary && setActive("supportUs", true)
      }
      onMouseLeave={() =>
        !isMobile && !inPrimary && setActive("supportUs", false)
      }
      onClick={() => handleClick()}
      role="support">
      <MainItem
        inPrimary={inPrimary}
        isActive={isActive}
        label={label}
        url={url}
        items={items}
      />
      {!inPrimary && items.length > 0 && (
        <div
          className={cx([
            { submenu: true, on: isActive, "in-primary": inPrimary },
            submenu
          ])}>
          <nav role="scroll-wrapper">
            <Menu
              menuClasses="primary"
              items={items}
              location="support"
              icon={true}
            />
          </nav>
        </div>
      )}
      {inPrimary && isActive && items.length > 0 && (
        <Menu
          menuClasses="support"
          items={items}
          location="support"
          icon={true}
        />
      )}
    </div>
  )
}

function MainItem({ inPrimary, isActive, label, url, items }) {
  const cx = classNames.bind(styles)

  if (!inPrimary) {
    return (
      <Link
        item={{ label: label, url: url }}
        className={cx({ "main-item": true })}
        legacyBehavior>
        {items.length > 0 && (
          <CaretRounded className={cx({ caret: true, rotate: isActive })} />
        )}
      </Link>
    )
  }

  return (
    <span className={cx({ "main-item": true })}>
      {label}
      {items.length > 0 && (
        <CaretRounded className={cx({ caret: true, rotate: isActive })} />
      )}
    </span>
  )
}
